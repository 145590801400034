<template>
  <div id="reportTable">
    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>

    <!-- Compare Table -->

    <div v-if="this.compare.length">
      <table>
        <thead v-bind:style="{ 'background-color':  reportColour}"> 
          <tr>
            <template v-if="this.home === true">
              f
            </template>
            <template v-else>
              <th></th>
            </template>
            <th>
              Pos
            </th>
            <template v-if="home == false" >
              <th v-if="data.live_oom !== '' && data.live_points_code !== ''" class="hideOnMob centerText">
                <span class="projectedOoM">Projected</span> <span class="oomPos">OoM Pos</span>
              </th>
            </template>
            <th class="hideOnMob">
              Country
            </th>
            <th>
              Player(S)
            </th>
            <th>
              Par
            </th>
            <template v-if="this.home === false">
              <th v-for="(i, roundIt) in range(1, roundsPlayed)" :key="roundIt">
                <span v-if="roundsPlayed === 1">
                  Score
                </span>
                <span v-else> R{{ i }} </span>
              </th>  
            </template>
            <th v-if="roundsPlayed > 1">
              Score
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tabledata, index) in compare" :key="index">
            <td></td>
            <td
              class="posnum"
              v-if="
                index !== 0 &&
                  (tabledata.tied !== '=' ||
                    Eds[index - 1].pos !== tabledata.pos)
              "
            >
              <span>
                {{ tabledata.pos }}
              </span>
            </td>
            <td class="posnum" v-else-if="index === 0">
              <span>
                {{ tabledata.pos }}
              </span>
            </td>
            <td v-else class="noPos"><span>.</span></td>
              <td
                v-if="
                  home == false &&
                    data.live_oom !== '' &&
                    data.live_points_code !== '' &&
                    tabledata.pos !== 'CUT'
                "
                class="hideOnMob centerText"
              >
                <span
                  :class="{
                    Arrowup: tabledata.oom_diff_pos > 0,
                    Arrowdown: tabledata.oom_diff_pos < 0
                  }"
                  class="scores-pos"
                  v-if="
                    tabledata.oom_pos.length > 0 && tabledata.oom_pos !== '0'
                  "
                >
                  {{ tabledata.oom_pos }}
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos > 0"
                    class="icon"
                    :icon="['fa', 'arrow-up']"
                  />
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos < 0"
                    class="icon"
                    :icon="['fa', 'arrow-down']"
                  />
                </span>
              </td>
            <td class="hideOnMob">
              <span>
                <img
                  class="flag"
                  :src="
                    ('https://assets.ocs-sport.com/flags/svg/flag_' +
                      tabledata.nationality)
                      | lowercase
                  "
                />
                {{ tabledata.city }}
              </span>
            </td>
            <td
              :class="{
                'text-up': tabledata.posChang == 'up',
                'text-down': tabledata.posChang == 'down',
              }"
            >
              <a :href="'/playerprofile/' + tabledata.member_no" class="nameLink">
                {{ tabledata.playing_name }}
                <span v-if="tabledata.sponsored === 'Y'"
                  ><b-img
                    class="titleFlag"
                    src="https://assets.asiantour.com/asian-tour/2018/11/titleist_oom-copy.png"
                  ></b-img
                ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpan" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
                <span v-if="tabledata.gender === 'F'" class="genderBall" :class="{female : tabledata.gender == 'F'}">•</span>
              </a>
            </td>
            <td v-if="tablePick === 'Final'">
              {{ tabledata.winnings }}
            </td>
            <td v-if="tabledata.vspar < 0" class="up">
              {{ tabledata.vspar }}
            </td>
            <td v-else-if="tabledata.vspar > 0" class="down">
              {{ tabledata.vspar }}
            </td>
            <td v-else>
              {{ tabledata.vspar }}
            </td>
            <td v-for="(i, roundIt) in range(1, roundsPlayed)" :key="roundIt">
              <span
                v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                class="up"
                >{{ tabledata.score }}</span
              >
              <span
                v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                class="down"
                >{{ tabledata.score }}</span
              >
              <span v-else-if="roundsPlayed === 1">{{ tabledata.score }}</span>
              <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                tabledata["score_R" + i]
              }}</span>
              <span v-else-if="tabledata['vspar_R' + i] > 0" :class="down">{{
                tabledata["score_R" + i]
              }}</span>
              <span v-else>{{ tabledata["score_R" + i] }}</span>
            </td>
            <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
              {{ tabledata.score }}
            </td>
            <td
              v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
              class="down"
            >
              {{ tabledata.score }}
            </td>
            <td v-else-if="roundsPlayed > 1">
              {{ tabledata.score }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Reports Table -->
    <table>
      <thead v-bind:style="{ 'background-color':  reportColour}">
        <tr>
          <template v-if="(tablePick === 'Score' && data.match_play === 'N') || (tablePick === 'Live' && data.match_play === 'N')
            "
          >
            <template v-if="home == false">
              <th></th>
            </template>
            <th>
              Pos
            </th>
            <template v-if="home == false" >
              <th v-if="data.live_oom !== '' && data.live_points_code !== ''" class="hideOnMob centerText">
                <span class="projectedOoM">Projected</span> <span class="oomPos">OoM Pos</span>
              </th>
            </template>
            <template v-if="home == false" >
              <th class="hideOnMob">
                Country
              </th>
            </template>
            <th>
              Players<span class="playerS">(s)</span>
            </th>
            <template v-if="data.bball_team == 'Y'">
              <th class="PadLeft">
                Par
              </th>
            </template>
            <template v-else>
              <th
                class="PadLeft"
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'N')
                "
              >
                Par
              </th>
            </template>
            <th
              class="PadLeft"
              v-if="
                data.score_type === 'P' ||
                  (data.tt_type === 'P' && data.stableford === 'Y')
              "
            >
              Points
            </th>
            <template v-if="this.title.includes('Scoreboard')">
              <th
                v-for="(i, roundIt) in range(1, roundsPlayed)"
                :key="roundIt"
                class="hideOnMob"
              >
                <span v-if="roundsPlayed === 1 && tablePick === 'Score'">
                  Score
                </span>
                <span v-else> R{{ i }} </span>
              </th>
              <template v-if="data.scores.scores_entry[0].score_found === 'Y'">
                <th v-if="roundsPlayed > 1">
                  Score
                </th>
              </template>
              <template v-if="roundsPlayed > 1 && tablePick === 'Score'">
                <th
                  class=""
                  v-if="
                    config.VUE_APP_PLAYER_ICON === 'Y' &&
                      data.scores.scores_entry[0].score_found === 'Y' &&
                      data.last_round_complete === 'Y'
                  "
                ></th>
              </template>
            </template>
            <template
              v-if="
                data.scores.scores_entry[0].score_found === 'N' ||
                  data.scores.scores_entry.score_found === 'N' ||
                  data.round_head == 'Latest Scores'
              "
            >
              <th>
                Thru
              </th>
            </template>
            <template
              v-else-if="
                (data.scores.scores_entry[0].score_found === 'Y' &&
                  this.title.includes('Scores Only')) ||
                  this.title.includes('Latest Scores')
              "
            >
              <th>Score</th>
            </template>
          </template>
        </tr>
      </thead>
      <tbody>

      <template v-if="(tablePick === 'Score' && data.match_play === 'N') || (tablePick === 'Live' && data.match_play === 'N')">      
        <!-- 0-5 Leaderboard -->
        <template v-for="(tabledata, index) in Eds.slice(0,5)">
          <tr @click.stop="rowClicked(index)" :key="index">
              <td v-if="tabledata.pos !== 'CUT' && home === false">
                <b-form-checkbox
                  :id="tabledata.member_no"
                  v-model="compare"
                  :name="tabledata.member_no"
                  :value="tabledata"
                >
                </b-form-checkbox>
              </td>
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum scorePos"
                v-else-if="index !== 0"
              >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td class="posnum scorePos" v-else-if="index === 0" >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>


              <td v-else class="noPos"><span>.</span></td>
              <td
                v-if="
                  home == false &&
                    data.live_oom !== '' &&
                    data.live_points_code !== '' &&
                    tabledata.pos !== 'CUT'
                "
                class="hideOnMob centerText"
              >
                <span
                  :class="{
                    Arrowup: tabledata.oom_diff_pos > 0,
                    Arrowdown: tabledata.oom_diff_pos < 0
                  }"
                  class="scores-pos"
                  v-if="
                    tabledata.oom_pos.length > 0 && tabledata.oom_pos !== '0'
                  "
                >
                  {{ tabledata.oom_pos }}
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos > 0"
                    class="icon"
                    :icon="['fa', 'arrow-up']"
                  />
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos < 0"
                    class="icon"
                    :icon="['fa', 'arrow-down']"
                  />
                </span>
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT' && home === false">
                <span v-if="tabledata.nationality === ''"></span>
                <span class="FlagScores" v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                  {{ tabledata.represents }}
                </span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard(tabledata.id, (toggle = !toggle))"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                  <span class="Pname">
                    {{ tabledata.playing_name }}
                  </span>
                  <span v-if="tabledata.pro_ind == 'Am'" class="amature">(a)</span> 
                  <span v-if="tabledata.sponsored === 'Y' && home === false"
                    ><b-img class="titleFlag" :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"></b-img
                  ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpan" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
                <span v-if="tabledata.gender === 'F'" class="genderBall" :class="{female : tabledata.gender == 'F'}">•</span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                 @click="playerCard(tabledata.id, (toggle = !toggle))"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              > 
                <span class="Pname">
                  {{ tabledata.name }}
                </span>
                <span v-if="tabledata.sponsored === 'Y'"
                  ><b-img class="titleFlag" :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"></b-img
                ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpanTeam" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
              </td>
              <td v-if="tabledata.vspar < 0" class="up posDown">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.vspar > 0" class="down posDown">
                {{ tabledata.vspar }}
              </td>
              <td class="ParBlack" v-else-if="tabledata.pos !== 'CUT'">
                {{ tabledata.vspar }}
              </td>
              <template v-if="title.includes('Scoreboard') && tabledata.pos !== 'CUT' &&
                    home === false">
                <template v-if="home === false">
                  <td
                    v-for="(i, roundIt) in range(1, roundsPlayed)"
                    :key="roundIt"
                  >
                    <span
                      v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                      class="up posDown"
                      >{{ tabledata.score }}</span
                    >
                    <span
                      v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                      class="down posDown"
                      >{{ tabledata.score }}</span
                    >
                    <span class="ParBlack" v-else-if="roundsPlayed === 1">{{
                      tabledata.score
                    }}</span>
                    <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" class="posDown">{{
                      tabledata["score_R" + i]
                    }}</span>
                    <span
                      class="posDown"
                      v-else-if="tabledata['vspar_R' + i] > 0"
                      :class="down"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                    <span class="posDown" v-else>{{ tabledata["score_R" + i] }}</span>
                  </td>
                </template>
                <template
                  v-if="
                    data.round_head == 'Latest Scores' ||
                      (tabledata.score_found == 'N' && tabledata.pos !== 'CUT')
                  "
                >
                  <td v-if="tabledata.pos !== 'CUT'" style="color: #6c6c6c; font-weight:bold;" :class="{thruColumn : home == false}">
                    <template
                      v-if="tabledata.holes && tabledata.holes.length > 0"
                    >
                      <span class="holeNum" v-show="tabledata.holes != '18'">{{
                        tabledata.holes
                      }}
                        <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                          >*</span
                        >
                        <span v-if="data.rts[1] == 'S'" class="strokesColour">{{tabledata['strokes_R' + data.pft_round].split(",")[1]}}</span>
                      </span>
                      <span class="holeNum" v-show="tabledata.holes == '18'">F</span>
                    </template>
                    <template v-else>
                      <span class="teeNum" v-if="tabledata['score_R' + data.pft_round] == '' || tabledata['score_R' + data.pft_round] == 'RTD' && tabledata['score_R' + data.pft_round] == 'WDN' && tabledata['score_R' + data.pft_round] == 'DSQ'">
                        {{ tabledata["time_R" + data.pft_round] }}
                      </span>
                    </template>
                  </td>
                </template>
                <template v-else>
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up posDown">
                    {{ tabledata.score }} 
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down posDown"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1" class="ParBlack">
                    {{ tabledata.score }}
                  </td>
                  <td ></td>
                </template>
              </template>
              <template
                v-else
              >
                <template v-if="tabledata.score_found == 'Y'">
                  <td v-if="tabledata.vspar < 0" class="up posDown">
                      {{ tabledata.score }} 
                    </td>
                    <td
                      v-else-if="tabledata.vspar > 0"
                      class="down posDown"
                    >
                      {{ tabledata.score }}
                    </td>
                    <td v-else class="ParBlack">
                      {{ tabledata.score }}
                    </td>
                </template>
                <template v-else>
                  <td v-if="tabledata.pos !== 'CUT'" style="color: #6c6c6c; font-weight: bold" >
                    <template
                      v-if="tabledata.holes && tabledata.holes.length > 0"
                    >
                      <span class="holeNum" v-show="tabledata.holes != '18'">{{
                        tabledata.holes
                      }}
                        <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                          >*</span
                        >
                      </span>
                      <span class="holeNum" v-show="tabledata.holes == '18'">F</span>
                    </template>
                    <template v-else-if="tabledata.score_found == 'Y' && data.tt_type != 'P'">
                      <span style="font-weight: bold"> {{ tabledata.score}}</span>
                        <!-- <td v-if="tabledata.vspar < 0 && roundsPlayed > 0" class="up">
                            {{ tabledata.score }}
                        </td>
                        <td
                          v-else-if="tabledata.vspar > 0 && roundsPlayed > 0"
                          class="down"
                        >
                          {{ tabledata.score }}
                        </td>
                        <td v-else-if="roundsPlayed > 0">
                          <span :class="{
                            nonScore: tabledata.vspar == 'RTD',
                            nonScoreone: tabledata.vspar == 'DSQ',
                            nonScoretwo: tabledata.score == 'WDN',
                            nonScorethree: tabledata.score == 'DSQ',
                            nonScorefour: tabledata.score == 'RTD'
                          }">
                            {{ tabledata.score }}
                          </span>
                        </td> -->
                      <!-- {{ tabledata["time_R" + data.pft_round] }} -->
                    </template>
                    <template v-else>
                      <!-- <span v-if="tabledata['score_R' + data.pft_round] == 'RTD' && tabledata['score_R' + data.pft_round] == 'WDN' && tabledata['score_R' + data.pft_round] == 'DSQ'"> -->
                       <span class="teeNum"> {{ tabledata["time_R" + data.pft_round] }}</span>
                      <!-- </span> -->
                    </template>
                  </td>
                </template>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/playerprofile/' + tabledata.member_no">
                  <font-awesome-icon
                    style="color:#f2af15!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
          </tr>
          <tr
             v-if="index === clickedRow && toggle == true"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :playerData="playerData"
                :playerDataTeam="playerDataTeam"
                :data="data"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
                :scoreType="data.scores_type"
                :team="team"
                :courses="courses"
                :stablford="data.stableford"
                :rts="data.rts[0]"
              />
            </td>
          </tr>
        </template>
        <!-- ADvert 1 Footjoy -->
        <!-- <tr style="background-color:red;"><td style="color:white;" colspan="11">Advert 1 Footjoy</td></tr> -->
        <!-- <tr class="middle-sec showMob">
          <td colspan="11" style="text-align: center;">
            <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
            <script type="application/javascript">
              window.googletag = window.googletag || {cmd: []};
              googletag.cmd.push(function() {
              billboardsizes = googletag.sizeMapping()
              .addSize([0,0],[320,50])
              .build();
              googletag.defineSlot('/2835490/New_PGA_Pos_25_Leaderboard', [[320, 50]], 'div-gpt-ad-1611877803779-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
              });
            </script>

            <div id='div-gpt-ad-1611877803779-0' class="lazy">
              <div>
                <script type="application/javascript">
                  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1611877803779-0'); });
                </script>
              </div>
            </div>
          </td>
        </tr> -->
        <tr class="middle-sec">
          <td colspan="11" style="text-align: center;">
            <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
            <script type="application/javascript">
              window.googletag = window.googletag || {cmd: []};
              googletag.cmd.push(function() {
              billboardsizes = googletag.sizeMapping()
              .addSize([980,690],[728,90])
              .addSize([0,0],[320,50])
              .build();
              googletag.defineSlot('/2835490/New_PGA_Tier2_Banner', [[970, 250], [728, 90],  [320, 50],], 'div-gpt-ad-1568956460842-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
              });
            </script>

            <div id='div-gpt-ad-1568956460842-0' class="lazy">
              <div>
                <script type="application/javascript">
                  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568956460842-0'); });
                </script>
              </div>
            </div>
          </td>
        </tr>
        <!-- 5-25 Leaderboard -->
        <template v-for="(tabledata, index) in Eds.slice(5,25)">
          <tr @click.stop="rowClickedTwo(index + 5)" :key="index">
              <td v-if="tabledata.pos !== 'CUT' && home === false">
                <b-form-checkbox
                  :id="tabledata.member_no"
                  v-model="compare"
                  :name="tabledata.member_no"
                  :value="tabledata"
                >
                </b-form-checkbox>
              </td>
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum scorePos"
                v-else-if="index !== 0"
              >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td class="posnum scorePos" v-else-if="index === 0" >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>
              <td
                v-if="
                  home == false &&
                    data.live_oom !== '' &&
                    data.live_points_code !== '' &&
                    tabledata.pos !== 'CUT'
                "
                class="hideOnMob centerText"
              >
                <span
                  :class="{
                    Arrowup: tabledata.oom_diff_pos > 0,
                    Arrowdown: tabledata.oom_diff_pos < 0
                  }"
                  class="scores-pos"
                  v-if="
                    tabledata.oom_pos.length > 0 && tabledata.oom_pos !== '0'
                  "
                >
                  {{ tabledata.oom_pos }}
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos > 0"
                    class="icon"
                    :icon="['fa', 'arrow-up']"
                  />
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos < 0"
                    class="icon"
                    :icon="['fa', 'arrow-down']"
                  />
                </span>
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT' && home === false">
                <span v-if="tabledata.nationality === ''"></span>
                <span class="FlagScores" v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                  {{ tabledata.represents }}
                </span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard(tabledata.id, (toggle = !toggle))"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                  <span class="Pname">
                    {{ tabledata.playing_name }}
                  </span>
                  <span v-if="tabledata.pro_ind == 'Am'" class="amature">(a)</span> 
                  <span v-if="tabledata.sponsored === 'Y' && home === false"
                    ><b-img class="titleFlag" :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"></b-img
                  ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpan" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
                <span v-if="tabledata.gender === 'F'" class="genderBall" :class="{female : tabledata.gender == 'F'}">•</span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                 @click="playerCard(tabledata.id, (toggle = !toggle))"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              > 
                <span class="Pname">
                  {{ tabledata.name }}
                </span>
                <span v-if="tabledata.sponsored === 'Y'"
                  ><b-img class="titleFlag" :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"></b-img
                ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpanTeam" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
              </td>
              <td v-if="tabledata.vspar < 0" class="up posDown">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.vspar > 0" class="down posDown">
                {{ tabledata.vspar }}
              </td>
              <td class="ParBlack" v-else-if="tabledata.pos !== 'CUT'">
                {{ tabledata.vspar }}
              </td>
              <template v-if="title.includes('Scoreboard') && tabledata.pos !== 'CUT' &&
                    home === false">
                <template v-if="home === false">
                  <td
                    v-for="(i, roundIt) in range(1, roundsPlayed)"
                    :key="roundIt"
                  >
                    <span
                      v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                      class="up posDown"
                      >{{ tabledata.score }}</span
                    >
                    <span
                      v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                      class="down posDown"
                      >{{ tabledata.score }}</span
                    >
                    <span class="ParBlack" v-else-if="roundsPlayed === 1">{{
                      tabledata.score
                    }}</span>
                    <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" class="posDown">{{
                      tabledata["score_R" + i]
                    }}</span>
                    <span
                      class="posDown"
                      v-else-if="tabledata['vspar_R' + i] > 0"
                      :class="down"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                    <span class="posDown" v-else>{{ tabledata["score_R" + i] }}</span>
                  </td>
                </template>
                <template
                  v-if="
                    data.round_head == 'Latest Scores' ||
                      (tabledata.score_found == 'N' && tabledata.pos !== 'CUT')
                  "
                >
                  <td v-if="tabledata.pos !== 'CUT'" style="color: #6c6c6c; font-weight:bold;" :class="{thruColumn : home == false}">
                    <template
                      v-if="tabledata.holes && tabledata.holes.length > 0"
                    >
                      <span class="holeNum" v-show="tabledata.holes != '18'">{{
                        tabledata.holes
                      }}
                        <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                          >*</span
                        >
                        <span v-if="data.rts[1] == 'S'" class="strokesColour">{{tabledata['strokes_R' + data.pft_round].split(",")[1]}}</span>
                      </span>
                      <span class="holeNum" v-show="tabledata.holes == '18'">F</span>
                    </template>
                    <template v-else>
                      <span class="teeNum" v-if="tabledata['score_R' + data.pft_round] == '' || tabledata['score_R' + data.pft_round] == 'RTD' && tabledata['score_R' + data.pft_round] == 'WDN' && tabledata['score_R' + data.pft_round] == 'DSQ'">
                        {{ tabledata["time_R" + data.pft_round] }}
                      </span>
                    </template>
                  </td>
                </template>
                <template v-else>
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up posDown">
                    {{ tabledata.score }} 
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down posDown"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1" class="ParBlack">
                    {{ tabledata.score }}
                  </td>
                  <td ></td>
                </template>
              </template>
              <template
                v-else
              >
                <template v-if="tabledata.score_found == 'Y'">
                  <td v-if="tabledata.vspar < 0" class="up posDown">
                      {{ tabledata.score }} 
                    </td>
                    <td
                      v-else-if="tabledata.vspar > 0"
                      class="down posDown"
                    >
                      {{ tabledata.score }}
                    </td>
                    <td v-else class="ParBlack">
                      {{ tabledata.score }}
                    </td>
                </template>
                <template v-else>
                  <td v-if="tabledata.pos !== 'CUT'" style="color: #6c6c6c; font-weight: bold" >
                    <template
                      v-if="tabledata.holes && tabledata.holes.length > 0"
                    >
                      <span class="holeNum" v-show="tabledata.holes != '18'">{{
                        tabledata.holes
                      }}
                        <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                          >*</span
                        >
                      </span>
                      <span class="holeNum" v-show="tabledata.holes == '18'">F</span>
                    </template>
                    <template v-else-if="tabledata.score_found == 'Y' && data.tt_type != 'P'">
                      <span style="font-weight: bold"> {{ tabledata.score}}</span>
                        <!-- <td v-if="tabledata.vspar < 0 && roundsPlayed > 0" class="up">
                            {{ tabledata.score }}
                        </td>
                        <td
                          v-else-if="tabledata.vspar > 0 && roundsPlayed > 0"
                          class="down"
                        >
                          {{ tabledata.score }}
                        </td>
                        <td v-else-if="roundsPlayed > 0">
                          <span :class="{
                            nonScore: tabledata.vspar == 'RTD',
                            nonScoreone: tabledata.vspar == 'DSQ',
                            nonScoretwo: tabledata.score == 'WDN',
                            nonScorethree: tabledata.score == 'DSQ',
                            nonScorefour: tabledata.score == 'RTD'
                          }">
                            {{ tabledata.score }}
                          </span>
                        </td> -->
                      <!-- {{ tabledata["time_R" + data.pft_round] }} -->
                    </template>
                    <template v-else>
                      <!-- <span v-if="tabledata['score_R' + data.pft_round] == 'RTD' && tabledata['score_R' + data.pft_round] == 'WDN' && tabledata['score_R' + data.pft_round] == 'DSQ'"> -->
                       <span class="teeNum"> {{ tabledata["time_R" + data.pft_round] }}</span>
                      <!-- </span> -->
                    </template>
                  </td>
                </template>
              </template>

              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/playerprofile/' + tabledata.member_no">
                  <font-awesome-icon
                    style="color:#f2af15!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
          </tr>
          <tr
             v-if="index + 5 === clickedRowTwo && toggle == true"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :playerData="playerData"
                :playerDataTeam="playerDataTeam"
                :data="data"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
                :scoreType="data.scores_type"
                :team="team"
                :courses="courses"
                :stablford="data.stableford"
                :rts="data.rts[0]"
              />
            </td>
          </tr>
        </template>
        <!-- ADvert 2 Footjoy -->
        <!-- <tr style="background-color:red;"><td style="color:white;" colspan="11">Advert 2 Footjoy</td></tr> -->
        <!-- <tr class="middle-sec showMob" style="text-align: center;">
          <td colspan="11">
          <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
            <script type="application/javascript">
              window.googletag = window.googletag || {cmd: []};
              googletag.cmd.push(function() {
              billboardsizes = googletag.sizeMapping()
              .addSize([0,0],[320,50])
              .build();
              googletag.defineSlot('/2835490/New_PGA_Top_Banner_BB', [[320, 50]], 'div-gpt-ad-1567045864014-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
              });
            </script>
            <div id='div-gpt-ad-1567045864014-0' class="lazy">
              <script type="application/javascript">
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1567045864014-0'); });
              </script>
            </div>
          </td>
        </tr> -->
        <tr class="middle-sec" style="text-align: center;">
          <td colspan="11">
          <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
            <script type="application/javascript">
              window.googletag = window.googletag || {cmd: []};
              googletag.cmd.push(function() {
              billboardsizes = googletag.sizeMapping()

              .addSize([980,690],[728,90])
              .addSize([0,0],[320,50])
              .build();
              googletag.defineSlot('/2835490/New_PGA_Lower_Banner', [[970, 250], [728, 90], [320, 50],], 'div-gpt-ad-1568956585834-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
              });
            </script>
            <div id='div-gpt-ad-1568956585834-0' class="lazy">
              <script type="application/javascript">
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568956585834-0'); });
              </script>
            </div>
          </td>
        </tr>
        <!-- 25-1000 Leaderboard -->
        <template v-for="(tabledata, index) in Eds.slice(25,1000)">
          <tr @click.stop="rowClickedThree(index + 25)" :key="index">
              <td v-if="tabledata.pos !== 'CUT' && home === false">
                <b-form-checkbox
                  :id="tabledata.member_no"
                  v-model="compare"
                  :name="tabledata.member_no"
                  :value="tabledata"
                >
                </b-form-checkbox>
              </td>
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum scorePos"
                v-else-if="index !== 0"
              >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td class="posnum scorePos" v-else-if="index === 0" >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>
              <td
                v-if="
                  home == false &&
                    data.live_oom !== '' &&
                    data.live_points_code !== '' &&
                    tabledata.pos !== 'CUT'
                "
                class="hideOnMob centerText"
              >
                <span
                  :class="{
                    Arrowup: tabledata.oom_diff_pos > 0,
                    Arrowdown: tabledata.oom_diff_pos < 0
                  }"
                  class="scores-pos"
                  v-if="
                    tabledata.oom_pos.length > 0 && tabledata.oom_pos !== '0'
                  "
                >
                  {{ tabledata.oom_pos }}
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos > 0"
                    class="icon"
                    :icon="['fa', 'arrow-up']"
                  />
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos < 0"
                    class="icon"
                    :icon="['fa', 'arrow-down']"
                  />
                </span>
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT' && home === false">
                <span v-if="tabledata.nationality === ''"></span>
                <span class="FlagScores" v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                  {{ tabledata.represents }}
                </span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard(tabledata.id, (toggle = !toggle))"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                  <span class="Pname">
                    {{ tabledata.playing_name }}
                  </span>
                  <span v-if="tabledata.pro_ind == 'Am'" class="amature">(a)</span> 
                  <span v-if="tabledata.sponsored === 'Y' && home === false"
                    ><b-img class="titleFlag" :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"></b-img
                  ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpan" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
                <span v-if="tabledata.gender === 'F'" class="genderBall" :class="{female : tabledata.gender == 'F'}">•</span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                 @click="playerCard(tabledata.id, (toggle = !toggle))"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              > 
                <span class="Pname">
                  {{ tabledata.name }}
                </span>
                <span v-if="tabledata.sponsored === 'Y'"
                  ><b-img class="titleFlag" :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"></b-img
                ></span>
                <span v-if="data.multi_course === 'Y'" class="multiCourseSpanTeam" :class="tabledata['course_colour_R' + pftroundsPlayed]">•</span>
              </td>
              <td v-if="tabledata.vspar < 0" class="up posDown">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.vspar > 0" class="down posDown">
                {{ tabledata.vspar }}
              </td>
              <td class="ParBlack" v-else-if="tabledata.pos !== 'CUT'">
                {{ tabledata.vspar }}
              </td>
              <template v-if="title.includes('Scoreboard') && tabledata.pos !== 'CUT' &&
                    home === false">
                <template v-if="home === false">
                  <td
                    v-for="(i, roundIt) in range(1, roundsPlayed)"
                    :key="roundIt"
                  >
                    <span
                      v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                      class="up posDown"
                      >{{ tabledata.score }}</span
                    >
                    <span
                      v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                      class="down posDown"
                      >{{ tabledata.score }}</span
                    >
                    <span class="ParBlack" v-else-if="roundsPlayed === 1">{{
                      tabledata.score
                    }}</span>
                    <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" class="posDown">{{
                      tabledata["score_R" + i]
                    }}</span>
                    <span
                      class="posDown"
                      v-else-if="tabledata['vspar_R' + i] > 0"
                      :class="down"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                    <span class="posDown" v-else>{{ tabledata["score_R" + i] }}</span>
                  </td>
                </template>
                <template
                  v-if="
                    data.round_head == 'Latest Scores' ||
                      (tabledata.score_found == 'N' && tabledata.pos !== 'CUT')
                  "
                >
                  <td v-if="tabledata.pos !== 'CUT'" style="color: #6c6c6c; font-weight:bold;" :class="{thruColumn : home == false}">
                    <template
                      v-if="tabledata.holes && tabledata.holes.length > 0"
                    >
                      <span class="holeNum" v-show="tabledata.holes != '18'">{{
                        tabledata.holes
                      }}
                        <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                          >*</span
                        >
                        <span v-if="data.rts[1] == 'S'" class="strokesColour">{{tabledata['strokes_R' + data.pft_round].split(",")[1]}}</span>
                      </span>
                      <span class="holeNum" v-show="tabledata.holes == '18'">F</span>
                    </template>
                    <template v-else>
                      <span class="teeNum" v-if="tabledata['score_R' + data.pft_round] == '' || tabledata['score_R' + data.pft_round] == 'RTD' && tabledata['score_R' + data.pft_round] == 'WDN' && tabledata['score_R' + data.pft_round] == 'DSQ'">
                        {{ tabledata["time_R" + data.pft_round] }}
                      </span>
                    </template>
                  </td>
                </template>
                <template v-else>
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up posDown">
                    {{ tabledata.score }} 
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down posDown"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1" class="ParBlack">
                    {{ tabledata.score }}
                  </td>
                  <td ></td>
                </template>
              </template>
              <template
                v-else
              >
                <template v-if="tabledata.score_found == 'Y'">
                  <td v-if="tabledata.vspar < 0" class="up posDown">
                      {{ tabledata.score }} 
                    </td>
                    <td
                      v-else-if="tabledata.vspar > 0"
                      class="down posDown"
                    >
                      {{ tabledata.score }}
                    </td>
                    <td v-else class="ParBlack">
                      {{ tabledata.score }}
                    </td>
                </template>
                <template v-else>
                  <td v-if="tabledata.pos !== 'CUT'" style="color: #6c6c6c; font-weight: bold" >
                    <template
                      v-if="tabledata.holes && tabledata.holes.length > 0"
                    >
                      <span class="holeNum" v-show="tabledata.holes != '18'">{{
                        tabledata.holes
                      }}
                        <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                          >*</span
                        >
                      </span>
                      <span class="holeNum" v-show="tabledata.holes == '18'">F</span>
                    </template>
                    <template v-else-if="tabledata.score_found == 'Y' && data.tt_type != 'P'">
                      <span style="font-weight: bold"> {{ tabledata.score}}</span>
                        <!-- <td v-if="tabledata.vspar < 0 && roundsPlayed > 0" class="up">
                            {{ tabledata.score }}
                        </td>
                        <td
                          v-else-if="tabledata.vspar > 0 && roundsPlayed > 0"
                          class="down"
                        >
                          {{ tabledata.score }}
                        </td>
                        <td v-else-if="roundsPlayed > 0">
                          <span :class="{
                            nonScore: tabledata.vspar == 'RTD',
                            nonScoreone: tabledata.vspar == 'DSQ',
                            nonScoretwo: tabledata.score == 'WDN',
                            nonScorethree: tabledata.score == 'DSQ',
                            nonScorefour: tabledata.score == 'RTD'
                          }">
                            {{ tabledata.score }}
                          </span>
                        </td> -->
                      <!-- {{ tabledata["time_R" + data.pft_round] }} -->
                    </template>
                    <template v-else>
                      <!-- <span v-if="tabledata['score_R' + data.pft_round] == 'RTD' && tabledata['score_R' + data.pft_round] == 'WDN' && tabledata['score_R' + data.pft_round] == 'DSQ'"> -->
                      <span class="teeNum">{{ tabledata["time_R" + data.pft_round] }}</span>
                      <!-- </span> -->
                    </template>
                  </td>
                </template>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/playerprofile/' + tabledata.member_no">
                  <font-awesome-icon
                    style="color:#f2af15!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
          </tr>
          <template v-if="data.hide_score_card == 'N'">
            <tr
              v-if="index + 25 === clickedRowThree && toggle == true"
              class="tr-kids"
              :key="index + Math.random()"
            >
              <td id="reportCard" colspan="11">
                <PlayerReportCard
                  :playerData="playerData"
                  :playerDataTeam="playerDataTeam"
                  :data="data"
                  :config="config"
                  :actualRound="data.pft_round"
                  :roundsPlayed="data.pft_round"
                  :scoreType="data.scores_type"
                  :team="team"
                  :courses="courses"
                  :stablford="data.stableford"
                  :rts="data.rts[0]"
                />
              </td>
            </tr>
          </template>
        </template>

      </template>


      </tbody>
    </table>
  </div>
</template>

<script>
import ads from '@/js/ads.js'

import PlayerReportCard from "@/ocs/playerReportCard.vue";
export default {
  name: "reportTable",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "season",
    "code",
    "tour",
    "team",
    "config",
    "match",
  ],
  components: { 
    PlayerReportCard,

  },
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      clickedRowTwo: -1,
      clickedRowThree: -1,
      cardID: [],
      toggle: "",
      playerCardData: [],
      win: "win",
      ads:ads,
      reportColour: process.env.VUE_APP_REPORT_MAIN_COLOUR
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    rowClickedTwo: function(index) {
      if (this.tablePick === "Score" && this.clickedRowTwo !== index)
        this.clickedRowTwo = index;
      else this.clickedRowTwo = -1;
    },
    rowClickedThree: function(index) {
      if (this.tablePick === "Score" && this.clickedRowThree !== index)
        this.clickedRowThree = index;
      else this.clickedRowThree = -1;
    },
    playerCard: function(playerNo) {
      if (this.toggle == true) {
        this.toggle = true;
        // console.log("this.toggle")
        // console.log(this.toggle)
        var playerData = {};
        // var cardID = event.target.getAttribute("title");
        console.log("playerData");
        console.log(playerData);
        var cardID = playerNo;
        console.log("cardID");
        console.log(cardID);
        for (var i of this.data.scores.scores_entry) {
          console.log("in scores");
          if (i.member_no == playerNo) {
            this.playerData = i;
            // console.log("playerData 998");
            // console.log(this.playerData);
            break;
          }
        }
        for (var t of this.data.team_scores.team_scores_entry) {
          // console.log("in scores");
          if (t.member_no == playerNo) {
            this.playerDataTeam = t;
            // console.log("playerData 998");
            // console.log(this.playerDataTeam);
            break;
          }
        }
      } else {
        this.toggle = false;
        // console.log("this.toggle")
        // console.log(this.toggle)
      }
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Tournament")) picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    pftroundsPlayed() {
      return parseInt(this.data.pft_round);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Tournament"))
          reportData = this.data.entries.entries_entry;
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
        else if (
          (this.title.includes("Scores") && this.team === "N") ||
          (this.title.includes("Scoreboard") && this.team === "N") ||
          (this.title.includes("Live") && this.team === "N")
        )
          reportData = this.data.scores.scores_entry;
        else if (
          (this.title.includes("Scores") && this.team === "Y") ||
          (this.title.includes("Scoreboard") && this.team === "Y") ||
          (this.title.includes("Live") && this.team === "Y")
        )
          reportData = this.data.team_scores.team_scores_entry;
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        ) {
          var allData = this.data.result.result_entry;
          var setOne = allData.slice(0, 5);
          var setTwo = allData.slice(5, 25);
          var setThree = allData.slice(25);
          var addOne = this.ads[0];
          var addTwo = "Test Two";
          let comone = setOne.concat(addOne);
          let comtwo = setTwo.concat(addTwo);
          let comthree = comone.concat(comtwo);
          reportData = comthree.concat(setThree);
        }
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry.slice(0, 8);
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, 10);
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard")
        )
          reportData = this.data.scores.scores_entry.slice(0, 7);
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry.slice(0, 7);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
span.oomPos {
  display: block;
  position: relative;
  left: 30px;
}
span.projectedOoM {
  display: block;
  position: relative;
  left: 30px;
}
span.teeNum {
  position: relative;
  top: 8px;
  color: #000;
  font-weight: 600; 
}
span.holeNum {
  position: relative;
  top: 8px;
  color: #000;
  font-weight: 600;
}
span.amature {
  position: relative;
  top: 5px;
}
span.Fclass {
  position: relative;
  top: 5px;
}
span.Pname {
  position: relative;
  top: 5px;
}
img.titleFlag {
  width: 30px;
  margin-left: 10px;
  position: relative;
  top: 5px;
}
.playerS {
  text-transform: lowercase;
}
td.hideOnMob.centerText {
  padding-left: 40px;
}
span.scores-pos.Arrowup {
  color: brown;
  position: relative;
  top: 5px;
}
span.scores-pos.Arrowdown {
  color: #1e90ff;
  position: relative;
  top: 5px;
}
.female {
  color: #e33;
}
.genderBall {
  font-size: 30px !important;
  line-height: 0 !important;
  position: relative;
  top: 10px;
}
 td > a {
  margin-top: 3px;
  display: block;
}
span.Pname:hover {
  color: rgb(51, 203, 169) !important;
}
a.nameLink{
  color: #000 !important;
}
.catSection {
  padding-top: 16px!important;
}
.homeEntriesPos {
  margin-top: 8px!important; 
}
.homeEntriesStatus {
  vertical-align: middle;
}
a.homeEntriesName {
  margin-top: 0px!important;
}
span.multiCourseSpanTeam {
  margin-top: 10px;
  float: right;
}
span.multiCourseSpan {
  float: right;
  margin-top: -17px;
}
span.multiCourseSpanTeam.B {
  color: #333333;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.Y {
  color: #d8db22;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.C {
  color: #1E90FF;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.R {
  color: #BB0000;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.G {
  color: #3c9933;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.O {
  color: #e07020;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpan.B {
  color: #333333;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  position: relative;
  top: 31px;
}
span.multiCourseSpan.Y {
  color: #d8db22;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  position: relative;
  top: 31px;
}
span.multiCourseSpan.C {
  color: #1E90FF;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  position: relative;
  top: 31px;
}
span.multiCourseSpan.R {
  color: #BB0000;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  position: relative;
  top: 31px;
}
span.multiCourseSpan.G {
  color: #3c9933;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  position: relative;
  top: 31px;
}
span.multiCourseSpan.O {
  color: #e07020;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  position: relative;
  top: 31px;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1E90FF;
}
.R {
  color: #BB0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
td.posDown {
  position: relative;
  padding-top: 20px;
}
td.posDownWinnings {
  margin-top: 10px;
  position: absolute;
}
span.posDown {
  position: relative;
  top: 8px;
}
td.ParBlack{
  position: relative;
  padding-top: 20px;
}
span.ParBlack{
  position: relative;
  top: 8px;
}
::v-deep span.down.posDown {
  position: relative;
  top: 8px;
}
td.down.posDown {
  position: relative;
  padding-top: 20px;
}
::v-deep span.up.posDown {
  position: relative;
  top: 8px;
}
td.up.posDown {
  position: relative;
  padding-top: 20px;
}
.scorePos {
  margin-left: 0px!important;
}
span.FlagScores {
  margin-top: 6px;
  display: block;
  color: #000;
}
::v-deep button#__BVID__32__BV_toggle_ {
  line-height: 35px;
  padding-bottom: 0;
  height: 40px;
}
td.drawName > a {
  margin-top: 1px;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px!important;
  padding-top: 7px!important;
  padding-bottom: 22px!important;
}
.title {
  text-align: center;
  background-color: #00433e;
  color: white;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  /* background-color: #00433e; */
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  /* background: #00433e; */
  color: #ffffff !important;
  text-align: left;
  padding-bottom: 12px;
  padding-top: 12px;
  z-index: 2;
}

.win {
  background-color: #02b2dd !important;
  color: black;
}
.catD {
  background-color: #d4d4d4;
  color: #003C52;
  /* padding-top: 20px; */
}
.drawName {
  font-size: 14px !important;
}
table {
  width: 100%;
}

thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: left;
}
td:nth-child(3) {
  text-align: left;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #fff;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  color: black;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
}
::v-deep td > a {
  /* margin-top: 6px; */
  display: block;
}
td {
  padding: 12px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none !important;
  cursor: pointer;
  text-align: left;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
::v-deep a:hover{
      color: #00b588!important;
      text-decoration: none;
}
@media only screen and (min-width: 991px) {
  .middle-sec.showMob {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .middle-sec.showDesk {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .tr-kids {
    display: none;
  }
  .amature {
    position: relative;
    top: 5px;
  }
  .titleFlag {
    width: 30px;
    /* margin-top: 8px; */
    margin-left: 8px;
  }
  td {
    font-size: 12px !important;
  }
  th {
    font-size: 13px;
  }
  .mobHide {
    display: none;
  }
  .hideOnMob {
    display: none;
  }
  #reportCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .custom-control.custom-checkbox {
    position: relative;
    left: 10px;
  }
  ::v-deep li.nav-item.hideHBH {
    display: none;
  }
  span.Pname {
    position: relative;
    top: 5px;
  }
  td.up.posDown {
    position: relative;
    padding-top: 18px;
  }
  span.up.posDown {
    position: relative;
    top: 6px!important;
  }
  .genderBall {
    font-size: 20px !important;
    line-height: 0 !important;
    position: relative;
    top: 7px;
    left: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .genderBall {
    font-size: 20px !important;
    line-height: 0 !important;
    position: relative;
    top: 7px;
    left: 10px;
  }
}
</style>