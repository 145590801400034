<template>
  <div class="quickScore">
      <b-row>
        <b-col lg="5" md="12">
          <b-row class="ShowMobile">
            <b-col>
              <b-img class="tournHeadLogoMob" :src="tmparams.sponsor_logo"></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h3 class="tournHead">
                {{ tmparams.full_name }}
              </h3>
              <h3 class="tournDates">
                {{ tmparams.dates }}
              </h3>
              <h3 class="tournDates">
                {{ tmparams.course_name }}
              </h3> 
            </b-col>
          </b-row>
          <b-row style="padding-top:20px;" class="paddBottom">
            <b-col>
              <template v-if="tmparams.winner_found === 'Y'">
                <h3 class="reportInfo" :style="TextColor">
                  {{ tmparams.winner_name }}
                </h3>
                <h3 class="tournDates">
                  Champion
                </h3>
              </template>
              <template v-if="tmparams.winner_found === 'N'">
                <h3 class="reportInfo" :style="TextColor" v-if="tmparams.previous_winners.previous_winners_entry.cc_name_1">
                  {{
                    tmparams.previous_winners.previous_winners_entry.cc_name_1
                  }}
                </h3>
                <h3 class="reportInfo" :style="TextColor" v-else>
                    N/A
                </h3>
                <h3 class="tournDates">
                  Defending Champion
                </h3>
              </template>
            </b-col>
            <b-col>
              <h3 class="reportInfo" :style="TextColor">
                {{ tmparams.total_prize_fund }}
              </h3>
              <h3 class="tournDates ">
                Purse
              </h3>
            </b-col>
          </b-row>
          <b-row
            class="tabRow"
            v-if="
              title === 'Scores Round ' + this.data.pft_round &&
                this.data.match_play === 'N'
            "
          >
            <b-col class="NoPad NopadLeft">
              <b-button
                class="tabBtn"
                :class="{ active: isHidden === '1' }"
                v-on:click="isHidden = '1'"
              >
                Leaderboard
              </b-button>
            </b-col>
            <b-col v-if="teamEvent === 'Y'" class="NoPad">
              <b-button
                class="tabBtn"
                :class="{ active: isHidden === '3' }"
                v-on:click="isHidden = '3'"
              >
                Team
              </b-button>
            </b-col>
            <b-col class="NoPad">
              <b-button
                class="tabBtn"
                :class="{ active: isHidden === '2' }"
                v-on:click="isHidden = '2'"
              >
                Hole-by-Hole
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-img class="tournHeadLogo" :src="tmparams.sponsor_logo"></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <iframe class="imob" src="https://static.rolex.com/clocks/2024/pga_australia_HTML_300x75/rolex.html"></iframe>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="hidebelow1200">
          <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
          <script type="application/javascript">
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function() {
            billboardsizes = googletag.sizeMapping()
            .addSize([980,690],[300,250])
            .addSize([0,0],[320,50])
            .build();
            googletag.defineSlot('/2835490/New_PGA_Top_MREC', [[320, 50], [300, 250]], 'div-gpt-ad-1568076309317-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
          </script>

          <!-- /2835490/New_PGA_Top_MREC -->
          <div id='div-gpt-ad-1568076309317-0' style="float: right;">
          <script type="application/javascript">
            googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568076309317-0'); });
          </script>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="tmparams.multi_course === 'Y'" lg="12" class="multi ShowMobile">
          <p class="coursesP">
            <span>Courses:</span>
          </p>
          <span
            class="multiList"
            v-for="(multi, index) in tmparams.courses.courses_entry"
            :key="index"
          >
            <span :class="multi.course_X_colour">•</span
            ><span class="multiName">{{ multi.course_X_id }}</span>
          </span>
        </b-col>
        <b-col lg="12" cols="12" :class="{colWidth: tmparams.multi_course === 'Y'}" class="ShowMobile">
          <b-row>
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <template #aside>
                  <b-img
                    width="50"
                    vertical-align="center"
                    :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"
                  ></b-img>
                </template>
                <p class="BallUsage">
                  = *Signifies Titleist golf ball usage
                </p>
              </b-media>
              <div>
                <p><span v-if="tmparams.show_gender == 'Y'" class="genderBall female"> <span class="namefemale">Female =</span> <span class="femaleBall">•</span></span></p>
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-bottom:30px">
            <!-- <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <template #aside>
                  <b-img
                    width="34"
                    :src="config.VUE_APP_ASSETS+'2020/04/Theauer.png'"
                    alt="placeholder"
                  ></b-img>
                </template>
                <p class="rookieYear">
                  - eligible for TAG Heuer Rookie of the Year
                </p>
              </b-media>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
      <b-row class="topPad">
        <b-col lg="6" md="6" cols="12" :class="{colWidth: tmparams.multi_course === 'Y'}">
          <div :class="drop">
            <b-nav card-header pills>
              <b-dropdown
                v-if="Array.isArray(tmparams.reports.reports_entry)"
                :text="title"
              >
                <b-dropdown-item
                  v-on:click="changeReport"
                  v-for="(reports, index) in filterExemptions"
                  :key="index"
                  :title="reports.report_url"
                  :value="reports.report_title"
                  v-show="!reports.report_title.includes('(Team)')"
                  >{{ reports.report_title.replace("(Pro)", "") }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown v-else text="Tournament Entries">
                <b-dropdown-item
                  v-on:click="changeReport"
                  v-for="(reports, index) in tmparams.reports"
                  :key="index"
                  :title="reports.report_url"
                  v-show="reports.report_title !== 'Scoring Statistics' && reports.report_title !== 'Course Statistics' && reports.report_title !== 'Course Statistics (COR)' && reports.report_title !== 'Course Statistics (REM)' && reports.report_title !== 'Statistics Tables'"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </div>
        </b-col>
        <b-col v-if="tmparams.multi_course === 'Y'" lg="4" class="multi hideMobile">
          <p class="coursesP">
            <span>Courses:</span>
          </p>
          <span
            class="multiList"
            v-for="(multi, index) in tmparams.courses.courses_entry"
            :key="index"
          >
            <span :class="multi.course_X_colour">•</span
            ><span class="multiName">{{ multi.course_X_id }}</span>
          </span>
        </b-col>
        <b-col lg="6" cols="12" :class="{colWidth: tmparams.multi_course === 'Y'}" class="hideMobile">
          <b-row>
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y',  nomarginleft : tmparams.show_gender == 'N'}">
                <template #aside>
                  <b-img
                    width="50"
                    vertical-align="center"
                    class="titleeistCopy"
                    :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"
                  ></b-img>
                </template>
                <p class="BallUsage">
                  = *Signifies Titleist golf ball usage <span v-if="tmparams.show_gender == 'Y'" class="genderBall female"> <span class="namefemale">, Female =</span> <span class="femaleBall">•</span></span>
                </p>
              </b-media>
            </b-col>
          </b-row>
          <b-row style="margin-bottom:30px">
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <!-- <template #aside>
                  <b-img
                    width="34"
                    :src="config.VUE_APP_ASSETS+'2020/04/Theauer.png'"
                    alt="placeholder"
                  ></b-img>
                </template>
                <p class="rookieYear">
                  - eligible for TAG Heuer Rookie of the Year
                </p> -->
              </b-media>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="Object.keys(data).length === 0">
        <b-row>
          <b-col>
            <h2 class="noData">
              No Data
            </h2>
          </b-col>
        </b-row>
      </div>
      <div v-else class="reports">
          <div
            v-if="
              this.currentReport.includes('tmentry') !== -1 ||
                this.currentReport.includes('tmdraw') !== -1 ||
                this.currentReport.includes('tmresult') !== -1 ||
                this.currentReport.includes('tmtsum') !== -1 ||
                this.currentReport.includes('tmoomt') !== -1
            "
          >
            <ReportTable
              :data="data"
              :title="title"
              :home="false"
              :updown="updown"
              :config="config"
              :team="teamEvent"
              :match="match"
            />
          </div>
          <div
            class="main"
            v-else-if="this.currentReport.includes('tmscore') !== -1"
          >
            <template v-if="data.match_play == 'N'">
              <b-tabs content-class="mt-3">
                <b-tab
                  title="Leaderboard"
                  active
                  title-item-class="hideLeadertab"
                >
                  <ReportTable
                    :data="data"
                    :home="home"
                    :season="tmparams.tm_params.season_code"
                    :code="tmparams.code"
                    :tour="this.$route.query.tour"
                    :team="'N'"
                    :title="title"
                    :updown="updown"
                    :config="config"
                    :match="match"
                  />
                </b-tab>
                <b-tab title="Team" v-if="teamEvent === 'Y'">
                  <ReportTable
                    :data="data"
                    :home="home"
                    :season="tmparams.tm_params.season_code"
                    :code="tmparams.code"
                    :tour="this.$route.query.tour"
                    :team="teamEvent"
                    :title="title"
                    :updown="updown"
                    :config="config"
                    :match="match"
                  />
                </b-tab>
                <b-tab title="Hole-by-Hole" title-item-class="hideHBH">
                  <HBH
                    :data="data"
                    :scores="data.scores.scores_entry"
                    :previousData="previousData"
                    :config="config"
                  />
                </b-tab>
              </b-tabs>
            </template>
            <template v-else>
              <ReportTable
                :data="data"
                :home="home"
                :season="tmparams.tm_params.season_code"
                :code="tmparams.code"
                :tour="this.$route.query.tour"
                :team="'N'"
                :title="title"
                :updown="updown"
                :config="config"
                :match="match"
              />
            </template>
          </div>
        </div>

      <!-- <b-tabs content-class="mt-3">
        <b-tab
          title="Leaderboard"
          active
          title-item-class="hideLeadertab"
        >
          <ReportTable
          :data="data"
          :title="title"
          :home="false"
          :updown="updown"
          :config="config"
          :team="teamEvent"
          :match="match"
          />
        </b-tab>
        <b-tab title="Team" v-if="teamEvent === 'Y'">
          <ReportTable
            :data="data"
            :title="title"
            :home="false"
            :updown="updown"
            :config="config"
            :team="teamEvent"
            :match="match"
          />
        </b-tab>
        <b-tab title="Hole-by-Hole" title-item-class="hideHBH">
          <HBH
            :data="data"
            :scores="data.scores.scores_entry"
            :previousData="previousData"
            :config="config"
          />
        </b-tab>
      </b-tabs> -->
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";
import HBH from "@/ocs/hbh.vue";
import axios from 'axios';
export default {
  name: "Reports Full",
  components: {
    ReportTable,
    HBH
  },
  props: ["Year", "code", "currentReport", "reportURL", "config" , "course_code", "title","tournDates", "updateSize", "widgetHeight", "reports", "tmparams",  "match"],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      data: [],
      tab: "tab",
      cell: "cell",
      home: true,
      MobWidget: process.env.VUE_APP_MOB_WIDGET,
      update: '',
      updateTwo: '',
      updateThree: '',
      tour:process.env.VUE_APP_TOUR
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.data.live_scoring === "Y") message = " - Livescoring is on";
      else message = " - " + this.data.round_head;
      return message;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
        // !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_TIC_BASE +
          this.tour +
          "/" +
          this.Year +
          "/" +
          this.Year +
          "-" +
          this.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
             process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
              this.Year +
              "/" +
              this.Year +
              "-" +
              this.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
              this.Year +
              "/" +
              this.Year +
              "-" +
              this.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
              this.Year +
              "/" +
              this.Year +
              "-" +
              this.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.tour +
            "/" +
            this.Year +
            "/" +
            this.Year +
            "-" +
            this.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.tour +
            "/" +
            this.Year +
            "/" +
            this.Year +
            "-" +
            this.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.tour +
            "/" +
            this.Year +
            "/" +
            this.Year +
            "-" +
            this.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
    // title: function(title) {
    //   if (this.currentReport.includes("entry")) title = "Tournament Entries";
    //   else if (this.currentReport.includes("draw")) title = "Draw";
    //   else if (this.currentReport.includes("latest")) title = "Live";
    //   else if (this.currentReport.includes("score")) title = "Scores";
    //   else if (this.currentReport.includes("result")) title = "Final Result";

    //   return title;
    // },
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        axios
          .get(this.reportTitle)
          .then(response => (this.data = response.data))
      );
    },
    updateStuff: function() {
      apiCall
        .report(this.currentReport)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    upDown() {
      this.updown = !this.updown;
    },
    // reportListText: function() {
    //   this.reports.forEach((value) => {
    //     if (value.report_title.includes('Draw')) {
    //       this.listOfreportNames = 'Draw'
    //       console.log("this.listOfreportNames")
    //       console.log(this.listOfreportNames)
    //     }
    //   });
    // },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    // this.reportListText();
    apiCall
      .report(this.currentReport)
      .then(({ data }) => {
        this.data = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.media.nomarginleft {
  margin-left: 17em;
}
span.namefemale {
  font-size: 10pt;
  font-weight: bold;
  color: #000;
}
.female {
  color: #e33;
}
.genderBall {
  font-size: 30px !important;
  line-height: 0 !important;
}
.femaleBall {
  position: relative;
  top: 4px;
}
.ad {
  margin-bottom: 30px;
  margin-top: 30px;
}
.titliestText {
  position: relative;
  top: 8px;
  left: 10px;
}
.titleistBll {
  display: -webkit-inline-box;
  padding-top: 20px;
}
.imob {
  border: none;
  /* width: 240px; */
  height: 80px;
  /* float: right; */
  position: relative;
  top: 60px;
}
.tRowTopMainnonMobile {
  background-color: transparent!important;
}
.tRowTopMain {
  background-color: rgba(0, 0, 0, 0.04);
}
.col-lg-6.col-12.colWidth {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 0px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  color: #000 !important;
}
::v-deep .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #003c52;
  border: 0px !important;
  color: #fff !important;
  border-top-left-radius: 0rem!important;
  border-top-right-radius: 0rem!important;
}
.media {
  margin-left: 13em;
}
.mediaNone {
  margin-left: 0em;
}
::v-deep .dropdown-item {
  color: #003C52!important;
}
.reportInfo {
  font-weight: 600;
  font-size: 1.5rem;
}
.coursesP {
  margin-bottom: 0px;
}
::v-deep .NopadLeft {
  padding-left: 15px!important;
}
::v-deep .NoPad {
  padding: 0px;
}
::v-deep button.btn.tabBtn.btn-secondary {
  height: 38px;
  padding-top: 7px;
  padding-bottom: 10px;
}
::v-deep button.btn.tabBtn.btn-secondary.active {
  height: 38px;
  padding-top: 7px;
  padding-bottom: 10px;
}
::v-deep button#__BVID__32__BV_toggle_ {
  /* line-height: 35px; */
    line-height: 0.6;
}
.BallUsage {
  font-size: 10pt;
  font-weight: bold;
}
.rookieYear {
  font-size: 10pt;
  font-weight: bold;
  margin-top: 8px;
}
#reports {
  /* background-color: #f9f9f9; */
}
.topPad {
  padding-top: 50px;
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 700;
  padding-left: 10px;
  line-height: 2;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
}
.menuLink > a {
  padding: 4px 0 0 3em;
  text-transform: capitalize;
  font-size: 10pt;
  font-weight: 500;
}
.tRow {
  border-bottom: 1px solid #000;
  padding: 10px 0;
}
.multi {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 600;
}
.multiList {
  /* padding: 0 10px; */
}
.multiName {
  color: #000000 !important;
  position: relative !important;
  top: -14px !important;
  /* padding-left: 17px; */
  padding-right: 10px;
  font-size: 14px !important;
}
.multiList > span {

}
.tournDates {
  text-align: left;
  color: #003c52;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  font-weight: 600;
  color: #003c52;
}
.tournHead {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 600;
  font-family: 'Gantari', sans-serif !important;
  color: #000;
  font-size: 50px;
  padding-bottom: 20px;
}
.tournHeadLogo {
  max-width: 323px;
  display: block;
  padding-bottom: 10px;
  width: 150px;
  /* float: right;  */
}
.tournHeadLogoMob {
  max-width: 323px;
  display: none;
  padding-bottom: 10px;
  width: 200px;
}
.ShowMobile.col-lg-12.col-12 {
  padding-top: 30px;
  padding-bottom: 25px;
}
.tabRow {
  padding: 0;
  padding-top: 20px;
  width: 400px;
}
.tabBtn {
  width: 100%;
  color: #0c4c39;
  background-color: #e6e6e6;
  border: none;
  /* padding: 10px 0; */
  text-align: center;
}
.active {
  color: white !important;
  /* background-color: #00b588 !important; */
}
::v-deep tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}
::v-deep .drop {
  padding-left: 10px;
  padding-bottom: 2%;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}

.nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
}

::v-deep .mt-3 {
  margin-top: 0 !important;
}
.B {
  color: #333333;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.Y {
  color: #d8db22;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.C {
  color: #1E90FF;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.R {
  color: #BB0000;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.G {
  color: #3c9933;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.O {
  color: #e07020;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}

.noData {
  text-align: center;
  padding: 70px;
  background-color: #00433e;
  color: white;
}
::v-deep button {
  color: black;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 0;
  width: 250px;
  text-align: left;
  line-height: 26px;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
::v-deep button::after {
  float: right;
  margin-top: 0px;
  position: relative;
  /* top: 3px; */
  top: 12px;
}
::v-deep button:hover {
  color: #003C52;
  background-color: white;
}

@media only screen and (min-width: 769px) {
  .ShowMobile {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .hidebelow1200 {
    display: none;
  }
  .tournHeadLogo {
    max-width: 323px;
    display: block;
    padding-bottom: 10px;
    width: 150px;
    float: left;
    margin-top: 30px;
  }
  .imob {
    border: none;
    /* width: 240px; */
    height: 80px;
    float: left;
    top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .showMob {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .showDesk {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep li.nav-item.hideHBH {
    display: none;
  }
  .paddBottom {
    padding-bottom: 30px;
  }
  ::v-deep .dropdown.b-dropdown.btn-group {
    width: 102.5%;
    margin-bottom: 17px;
  }
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
    width: 100%;
    display: block;
  }
  ::v-deep button {
    width: 100%!important;
  }
  .tournDates {
    text-align: left;
    color: #000;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 100;
    font-size: 14px;
    font-weight: 600;
  }
  .tournHeadLogo {
    display: none;
  }
  .media {
    margin-left: 0;
  }
  .hideMobile {
    display: none;
  }
  .topPad {
    padding-top: 0px;
  }
  .mobHide {
    display: none;
  }
  .tournHead {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 600;
    font-family: 'Gantari', sans-serif !important;
    color: #000;
    font-size: 33px;
  }
}
@media only screen and (max-width: 480px) {
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
}
</style>